import React from 'react';

import minimatch from 'minimatch';
import {find} from 'lodash';

import {URLConsumer} from './url-container';

import styles from './step.module.css';

const Step = ({children, active, inactive}) => (
  <URLConsumer>{({url}) => (
    <div className={stepStatusClass(url, active, inactive)}>
      {children}
    </div>
  )}</URLConsumer>
);

function stepStatusClass(url, active, inactive) {
  // Only try to work out a status if we know our URL
  if (!url) {
    return styles.active;
  }

  const path = new URL(url).pathname;
  
  if (active && active.length > 0) {
    const activeEl = find(active, opt => minimatch(path, opt));

    if (activeEl) {
      return styles.active;
    } else {
      return styles.inactive;
    }
  } else if (inactive && inactive.length > 0) {
    const inactiveEl = find(inactive, opt => minimatch(path, opt));

    if (inactiveEl) {
      return styles.inactive;
    } else {
      return styles.active;
    }
  } else {
    // Default to active
    return styles.active;
  }
}

export default Step;