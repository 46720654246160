import React from 'react'
import PropTypes from 'prop-types'

import styles from './host-page-link.module.css';

export default class UIElement extends React.Component {
  static propTypes = {
    to: PropTypes.string  
  }

  render() {
    return (
      <a className={styles.link} href="#" onClick={this._onClick}>
        {this.props.children}
      </a>
    );
  }

  _onClick = (e) => {
    e.preventDefault();

    window.parent.postMessage({action: 'navigate', url: this.props.to}, '*');
  }
}