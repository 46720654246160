import React from 'react'

import UIElement from './ui-element';
import HostPageLink from './host-page-link';

const UI_MATCHER = /^ui:(.*)$/,
      APP_LINK_MATCHER = /^app:(.*)$/;

const LinkSelector = (props) => {
  const {href, children, ...rest} = props;

  const ui = UI_MATCHER.exec(href);
  if (ui) {
    const [, target] = ui;

    return <UIElement target={decodeURIComponent(target)}>{children}</UIElement>;
  }

  const app = APP_LINK_MATCHER.exec(href);
  if (app) {
    const [, to] = app;

    return <HostPageLink to={decodeURIComponent(to)}>{children}</HostPageLink>;
  }

  return <a href={href} {...rest}>{children}</a>;
}

export default LinkSelector;