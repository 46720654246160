/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useEffect} from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react";
import { globalHistory } from "@reach/router";

// import Header from "./header"
import "./layout.css"
import UIElement from './ui-element';
import HostPageLink from './host-page-link';
import LinkSelector from './link-selector';
import Step from './step';

import {URLContainer} from './url-container';

const shortcodes = {
  UIElement,
  Link: HostPageLink,
  Step,

  a: LinkSelector
}

const Layout = ({ children }) => {
  // Track page navigation messages
  useEffect(() => {
    globalHistory.listen(({location, action}) => {
      const nextPage = location.href;

      window.parent.postMessage({action: 'notify-current-page', url: nextPage}, '*');
    });
  }, []);

  return (
    <>
      <div
        style={{
          padding: '20px 20px'
        }}
      >
        <URLContainer>
          <main>
            <MDXProvider components={shortcodes}>{children}</MDXProvider>
          </main>
        </URLContainer>
        <footer></footer>
      </div>
    </>
  )
}



Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
