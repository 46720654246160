import React from 'react'
import PropTypes from 'prop-types'

import styles from './ui-element.module.css';

export default class UIElement extends React.Component {
  static propTypes = {
    type: PropTypes.string,
    label: PropTypes.string,
    target: PropTypes.string,
  }

  render() {
    return (
      <span onMouseOver={this._mouseEnter} onMouseOut={this._mouseOut} className={styles.element}>
        {this.props.children}
      </span>
    );
  }

  _mouseEnter = () => {
    // Don't immediately respond to the entry event, just in case the user is moving their mouse and "accidentally" hovers.
    this.enteringId = setTimeout(() => {
      window.parent.postMessage({action: 'enter', element: {type: this.props.type, target: this.props.target}}, '*');
      this.enteringId = null;
    }, 200);
  }

  _mouseOut = () => {
    if (this.enteringId) {
      clearTimeout(this.enteringId);
      this.enteringId = null;
    }

    window.parent.postMessage({action: 'leave', element: {type: this.props.type, target: this.props.target}}, '*');
  }
}