import React, {useEffect, useState, useContext} from 'react';

const CurrentURLContext = React.createContext({url: '', categories: null});

export const URLContainer = ({children}) => {
  const [currentUrl, setCurrentUrl] = useState('');
  const [currentCategories, setCurrentCategories] = useState(null);

  // Track page navigation messages
  useEffect(() => {
    const _handleMessage = (event) => {
      if (!event.data || !event.data.action) return;

      if (event.data.action === 'page-navigation') {
        setCurrentUrl(event.data.url);
      } else if (event.data.action === 'update-categories') {
        setCurrentCategories(event.data.categories);
      }
    };

    window.addEventListener('message', _handleMessage);

    // Send a request for the existing URL and categories
    window.parent.postMessage({action: 'sync-page-navigation'}, '*');
    window.parent.postMessage({action: 'sync-categories'}, '*');

    return () => window.removeEventListener('message', _handleMessage);
  }, []);

  return (
    <CurrentURLContext.Provider value={{url: currentUrl, categories: currentCategories}}>
      {children}
    </CurrentURLContext.Provider>
  );
};

export const URLConsumer = ({children}) => {
  const url = useContext(CurrentURLContext);

  return children({url: url.url});
};

export const CategoriesConsumer = ({children}) => {
  const url = useContext(CurrentURLContext);

  if (url.categories !== null) {
    return children({categories: url.categories});
  } else {
    // Don't render until we have our category sync
    return null;
  }
};